<template>
  <div>
    <div class="all_context">
      <div class="all_context_flex">
        <div class="storeCenter_item_top_left">
          <div class="banner_box">
            <img v-if="headData.coverImage" :src="headData.coverImage" alt="" />
          </div>
          <div class="storeCenter_item_top_left_flex">
            <div style="display: flex; align-items: center">
              <img
                width="16"
                height="16"
                v-if="headData.reportType == '报告合集'"
                src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/%E5%8E%8B%E7%BC%A9%E5%8C%85%201.png"
                alt=""
              />
              <img
                width="16"
                height="16"
                v-else
                src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/PDF%20%281%29%201.png"
                alt=""
              />
              <h5 class="h5_name">
                {{ headData.reportName }}
              </h5>
            </div>

            <!-- <div>{{ item.sponsor }}</div> -->
            <div v-if="headData.releaseMechanism" class="releaseMechanism">
              出版机构：{{ headData.releaseMechanism }}
            </div>
            <!-- <div v-if="headData.releaseTime" class="releaseMechanism">
                  发布日期：{{ headData.releaseTime }}
                </div> -->
            <div class="h5_names">
              {{ headData.reportName }}
            </div>
          </div>
        </div>
        <div class="right">
          <div class="total_num">
            <span>{{ total || 0 }}</span>
          </div>

          <div>
            <span>已推送企业数</span>
          </div>
        </div>
      </div>
      <div class="context">
        <div class="state">
          <div class="left">
            <el-button style="margin-right: 5px" @click="updateOffShelf">批量下架</el-button>
          </div>

          <div class="right">
            <div class="seach_box">
              <el-input
                placeholder="请输入企业全称"
                v-model="query.queryConditions"
                class="input-with-select"
              >
                <el-button class="seach" slot="append" @click="search()">搜索</el-button>
              </el-input>
            </div>
          </div>
        </div>

        <div class="table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="loading"
            @sort-change="changeSort"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中..."
            @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          >
            <el-table-column type="selection" width="55" align="center"> </el-table-column>
            <el-table-column label="编号" align="center" width="140px" type="index">
            </el-table-column>

            <el-table-column prop="companyLogo" width="180" label="logo" align="center" show-overflow-tooltip>
          <template v-slot="{ row }">
            <el-image
            v-if="row.companyLogo"
                slot="reference"
                :src="row.companyLogo"
                style="width: 40px; height: 40px; border-radius: 5px"
                fit="fit"
              ></el-image>
              <el-image
                v-else
                slot="reference"
                src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/Group%203237.png"
                style="width: 40px; height: 40px; border-radius: 5px"
                fit="fit"
              ></el-image>

             
          </template>
        </el-table-column>
            <el-table-column label="企业名称" align="center">
              <template slot-scope="scope">{{ scope.row.companyFullName }}</template>
            </el-table-column>

            <el-table-column label="所在城市" align="center">
              <template slot-scope="scope">{{ scope.row.province }}-{{ scope.row.city }}</template>
            </el-table-column>
            <el-table-column label="标签" align="center">
              <template v-slot="{ row }">
                <span v-for="(o, index) in row.labels" :key="index">
                  <span v-if="index < 4 && o != ''" class="office">#{{ o }}</span>
                </span>
                <el-popover placement="bottom" width="230" trigger="hover">
                  <div
                    class="hidden_label_box"
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      align-content: center;
                      flex-wrap: wrap;
                    "
                  >
                    <span
                      class="office"
                      v-for="(o, index) in row.labels && row.labels.length
                        ? row.labels.filter((el, index) => index > 3)
                        : []"
                      :key="index"
                      style="
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        color: #4e93fb;
                        margin-right: 10px;
                      "
                      >#{{ o }}</span
                    >
                  </div>
                  <span
                    style="color: #4e93fb"
                    slot="reference"
                    v-if="row.labels && row.labels.length > 4"
                    >...{{ row.labels.length - 4 }}</span
                  >
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="关联度" align="center" width="100" prop="correlation" sortable>
              <template slot-scope="scope">
                <div class="correlationBox">
                  <div class="correlation">{{ scope.row.correlation }}%</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="企业简介" align="center" width="450">
              <template slot-scope="scope">
                <div
                  class="enterpriseIntro"
                  @mouseenter="enterpriseIntroShow = scope.row.id"
                  @mouseleave="enterpriseIntroShow = -1"
                >
                  {{ scope.row.enterpriseIntro }}
                </div>
                <div
                  class="enterpriseIntroShow"
                  v-show="enterpriseIntroShow == scope.row.id"
                  @mouseenter="enterpriseIntroShow = scope.row.id"
                  @mouseleave="enterpriseIntroShow = -1"
                >
                  {{ scope.row.enterpriseIntro }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template v-slot="{ row }">
                <div class="btn">
                  <span class="pass" v-if="row.isShelf == 0" @click="isShelf(row)">下架</span>
                  <span class="un_pass" v-if="row.isShelf == 1" @click="isShelf(row)">上架</span>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="Pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="query.pageNum"
              :page-sizes="[10, 30, 50]"
              :page-size="query.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reportById, pushedCompanyShelf, pushedCompanyPageReport } from '../../../api/content.js'
const defaultQueryInfo = Object.freeze({
  city: '',
  id: '',
  idType: '',
  orderByParam: '',
  pageNum: 1,
  pageSize: 10,
  province: '',
  queryConditions: ''
})
export default {
  name: 'PushEnterprise',
  computed: {
    activeId() {
      return this.$route.query.id
    }
  },
  data() {
    //这里存放数据
    return {
      headData: {}, ///头部信息
      // 表格数据
      tableData: [],
      loading: false,
      // 分页总条数
      total: 0,
      ids: [],
      // 表格发送数据

      query: { ...defaultQueryInfo },
      enterpriseIntroShow: false
    }
  },

  created() {
    this.getHeadData()
    this.search()
  },
  //方法集合
  methods: {
    handleSizeChange(val) {
      this.query.pageSize = val
      this.search()
    },
    handleCurrentChange(val) {
      this.query.pageNum = val
      this.search()
    },

    // 头部数据
    async getHeadData() {
      const { data: res } = await reportById({
        id: this.activeId
      })
      if (res.resultCode == 200) {
        this.headData = res.data
      }
    },

    // table表格按钮的选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
    },
    async search() {
      this.query.id = this.activeId
      this.loading = true
      const { data: res } = await pushedCompanyPageReport(this.query)

      if (res.resultCode == 200) {
        this.tableData = res.data.list
        this.total = res.data.total
        this.loading = false
        this.tableData.forEach((item) => {
          if (item.labels) {
            //根据字符截取标签
            item.labels = item.labels.split('~')
          } else {
            item.labels = []
          }
        })
      }
    },
    //单个上下架
    isShelf(row) {
      const query = {
        companyIds: [row.id],
        id: this.activeId,
        idType: '报告',
        isShelf: row.isShelf == 0 ? '1' : '0'
      }
      const that = this
      this.$alert(`是否确认${row.isShelf == 0 ? '下架' : '上架'}该企业？`)
        .then(function () {
          return pushedCompanyShelf(query)
        })
        .then(() => {
          this.search()
          this.$message.success(`${row.isShelf == 0 ? '下架' : '上架'}成功`)
        })
        .catch(() => {})
    },
    //批量下架
    updateOffShelf() {
      if (this.ids.length > 0) {
        const query = {
          companyIds: this.ids,
          id: this.activeId,
          idType: '报告',
          isShelf: 1
        }
        const that = this
        this.$alert('是否确认批量下架该企业？')
          .then(function () {
            return pushedCompanyShelf(query)
          })
          .then(() => {
            this.search()
            this.ids = []
            this.$refs.multipleTable.clearSelection()
            this.$message.success('下架成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择企业')
      }
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'correlation') {
        if (val.order == 'ascending') {
          //updateAt列升序时
          this.query.orders = '2'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.query.orders = '3'
          this.search()
        } else {
          //updateAt列不排序时
          this.query.orders = '1'
          this.search()
        }
      }
    }
  }
}
</script>
<style scoped lang="less">
.all_context {
  padding: 16px 0px 0 0px;
  .all_context_flex {
    display: flex;
    background-color: #ffffff;
    padding-right: 50px;
    border-radius: 8px;
    padding: 20px;
    position: relative;
    margin-bottom: 20px;
    .right {
      position: absolute;
      right: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .total_num {
        margin-bottom: 10px;
        font-size: 30px;
        font-weight: 550;
        color: #ff7d18;
      }
    }
  }
  .storeCenter_item_top_left {
    display: flex;
    flex-direction: row;
    height: 107.73px;
    width: 450px;
    .banner_box {
      position: relative;
      width: 185.55px;
      height: 117.73px;
      .generalVersion {
        background: #448aff;
        border-radius: 2px;
        padding: 5px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        /* identical to box height */
        margin: 0 10px;
        color: #ffffff;
        position: absolute;
        left: 0px;
        top: 5px;
      }
      img {
        width: 185.55px;
        height: 117.73px;
        border-radius: 4px;
        margin-right: 14px;
      }
      .activity_state {
        position: absolute;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 34px;
        text-align: center;
        color: #ffffff;
        top: -10px;
        left: -9px;
        width: 78px;
        height: 34px;
        border-radius: 5px 15px 15px 0;
      }
      .not_started {
        background: #4e93fb;
      }
      .have_in_hand {
        background: #ff7d18;
      }
      .closed {
        background: #999999;
      }
    }

    .storeCenter_item_top_left_flex {
      margin-left: 10px;
      .h5_name {
        display: inline-block;
        margin: 0;
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        width: 200px;
        -webkit-box-orient: vertical;
        margin-left: 5px;
        cursor: pointer;
      }
      .h5_names {
        display: inline-block;
        margin: 0;
        font-size: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        width: 320px;
        -webkit-box-orient: vertical;
        cursor: pointer;
      }
      .mark_box {
        display: flex;
        img {
          width: 14px;
          // height: 14px;
        }
        & > div {
          margin-left: 10px;
        }
      }
      .img_commer {
        display: flex;
        border: 1px solid #146aff;
        border-radius: 39px;
        .name {
          font-size: 12px;
          line-height: 20px;
          color: #146aff;
        }
        .color_img {
          background: #146aff;
          height: 20px;
          width: 25px;
          border-radius: 20px;
          position: relative;
          left: -10px;
          img {
            width: 13px;
            height: 13px;
            position: relative;
            left: 5px;
            top: 2px;
          }
        }
      }
      .img_commers {
        display: flex;
        border: 1px solid #ff9c00;
        border-radius: 39px;
        margin: 0px 10px;
        .name {
          font-size: 12px;
          line-height: 20px;
          color: #ff9c00;
        }
        .color_img {
          background: #ff9c00;
          height: 20px;
          width: 25px;
          border-radius: 20px;
          position: relative;
          left: -10px;
          img {
            width: 13px;
            height: 13px;
            position: relative;
            left: 5px;
            top: 2px;
          }
        }
      }

      h4 {
        color: #1f2d3d;
        font-size: 26px;
        font-weight: 500;
        margin: 0;
      }

      .storeCenter_item_top_left_flex_tag {
        padding-top: 10px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-content: center;

        margin-bottom: 1px;
        width: 500px;

        .hidden_label_box {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-content: center;
          flex-wrap: wrap;
        }
        span {
          box-sizing: border-box;
          display: block;
          font-size: 14px;
          font-weight: 400;
          border-radius: 4px;
        }

        .office {
          border: 1px solid #559eff;
          color: #4e93fb;
          padding: 1px 6px;
          margin-right: 10px;
        }

        .support {
          border: 1px solid #fd523f;
          color: #fd523f;
          margin-left: 12px;
        }
      }

      .releaseMechanism {
        width: 450px;
        padding: 10px 0px;
        font-size: 16px;
      }
    }
  }
  .seach_box {
    display: flex;
    margin: 15px 0;
    align-items: flex-end;
    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }
  .head {
    margin-bottom: 15px;
    height: 200px;
    display: flex;
    background-color: #ffffff;
    padding-right: 50px;
    border-radius: 8px;

    .img {
      width: 267px;
      height: 201px;
      // background-color: skyblue;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      img {
        width: 239px;
        height: 166px;
        border-radius: 4px;
      }
    }
    .describe {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title_label {
        flex: 1;
        display: flex;
        justify-content: space-between;
        padding-right: 50px;
        .left {
          flex: 1;
          padding-top: 19px;
          .title {
            color: #333333;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 10px;
          }
          .type {
            margin: 12px 0px;
            font-size: 14px;
            color: #333;
          }
          .issueName {
            margin-bottom: 21px;
            font-size: 14px;
            color: #333;
          }
          .label {
            width: 450px;
            color: #4e93fb;
            display: flex;
            flex-wrap: wrap;
            overflow: hidden; //超出隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
            // 控制行数
            -webkit-line-clamp: 2; //超出两行隐藏
            -webkit-box-orient: vertical; // 从上到下垂直排列子元素

            .item {
              display: inline-block;
              margin-right: 11px;
              margin-bottom: 10px;
            }
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .total_num {
            margin-bottom: 10px;
            font-size: 30px;
            font-weight: 550;
            color: #ff7d18;
          }
        }
      }
    }
  }
  .context {
    background-color: #ffffff;
    padding: 0 21px 0 30px;
    border-radius: 8px;
    .pass {
      color: #4e93fb;
      cursor: pointer;
    }
    .office {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #4e93fb;
      margin-right: 10px;
      cursor: pointer;
    }
    .un_pass {
      color: #fd6161;
      cursor: pointer;
    }
    .state {
      height: 72px;
      border-bottom: 1px dashed #f3f3f3;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        .label {
          font-size: 14px;
          color: #7c7f8e;
        }
        .item {
          color: #333333;
          font-size: 14px;
          cursor: pointer;
          padding: 15px 20px;
        }
      }
      .right {
        display: flex;
        padding-right: 25px;
        f .icon {
          width: 20px;
          height: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .tex {
          margin-left: 7px;
          color: #595959;
        }
        .time {
          color: #333333;
        }
      }
      /deep/.el-radio {
        margin-right: 0;
      }
    }
    .time_search {
      height: 80px;
      display: flex;
      align-items: center;
      padding-right: 10px;
      justify-content: space-between;
      position: relative;
      .time {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        position: absolute;
        left: 0;

        .label {
          font-size: 14px;
          color: #7c7f8e;
        }
        // .item {
        //   color: #333333;
        //   font-size: 14px;
        //   cursor: pointer;
        //   padding: 15px 20px;
        // }
      }
      .search {
        display: flex;
        position: absolute;
        right: 0;
        .search_input {
          width: 430px;
        }
        .search_div {
          width: 74px;
          height: 40px;
          background-color: #448aff;
          color: #fff;
          border-radius: 2px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          transform: translateX(-2px);
        }
      }
    }

    .table {
      .imgbox {
        display: flex;
        justify-content: center;

        .img {
          width: 34px;
          height: 34px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .correlationBox {
        display: flex;
        align-items: center;
        justify-content: center;
        .correlation {
          background-color: #fd6161;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 55px;
          height: 24px;
          color: #fff;
          font-size: 12px;
        }
      }

      .enterpriseIntro {
        // width: 100%;
        // height: 100%;
        width: 376px;
        overflow: hidden; //超出隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
        // 控制行数
        -webkit-line-clamp: 2; //超出两行隐藏
        -webkit-box-orient: vertical; // 从上到下垂直排列子元素
        color: #333333;
        position: relative;
      }
      .enterpriseIntroShow {
        position: absolute;
        // right: 0%;
        // bottom: -23%;
        border-radius: 8px;
        // transform: translateX(50%);
        width: 376px;
        padding: 10px 7px 8px 13px;
        background-color: #fff;
        z-index: 999;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }

      /deep/.el-table__body-wrapper {
        overflow: visible;
      }
      /deep/.el-table {
        overflow: visible;
      }
    }
    .Pagination {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 50px 0 50px 0;
    }
  }
}

.add_select {
  .remove_pool {
    box-sizing: border-box;
    width: 348px;
    height: 150px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    box-shadow: 0px 0px 2px #d4d4d4;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding: 42px 0 20px 0;
    align-items: center;
    .el-icon-close {
      position: absolute;
      right: 11px;
      top: 11px;
      color: #b6b6b4;
      font-size: 18px;
      cursor: pointer;
    }
    .text {
      margin-bottom: 23px;
    }
    /deep/.el-button {
      width: 112px;
      height: 40px;
    }
  }
  /deep/.el-dialog {
    margin-top: 40vh !important;
  }
}

.correlation {
  /deep/.el-dialog {
    border-radius: 4px;
    position: relative;
  }
  /deep/.el-dialog__body {
    padding: 28px 20px 16px 20px;
  }
  .correlationBox {
    .head {
      position: absolute;
      left: 0;
      top: 12px;
      // transform: translate(-20px, -50px);
      .title {
        border-left: 4px solid #448aff;
        padding-left: 15px;
        font-size: 16px;
        color: #333333;
        font-weight: 500;
      }
    }
    .context {
      .input {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .label {
          position: relative;
          font-size: 14px;
          color: #333333;
          span {
            position: absolute;
            right: -9px;
            top: -2px;
            color: #f05252;
          }
        }
        /deep/.el-input {
          width: 322px;
        }
      }
      .button {
        width: 100%;
        display: flex;
        justify-content: center;
        .text {
          width: 64px;
          height: 36px;
          cursor: pointer;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 14px;
          background-color: #448aff;
          margin-top: 31px;
        }
      }
    }
  }
}
</style>
